#sidebar {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  position: fixed;
  overflow: auto;
}

#menu-button:hover {
    color: rgb(206, 201, 201);
    cursor: pointer;
}

#sidebar-button:hover, #sidebar-icon:hover {
    cursor: pointer;
    color: rgb(206, 201, 201);
}

